import { Amplify } from 'aws-amplify'
import { Hub } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito'
import { defineNuxtPlugin, useAsyncData } from 'nuxt/app'
import { logout, login, getCurrentUser, getToken } from '~/utils/auth'
import { getAmplifyConfig, getCookieStorageConfig } from '~/aws-config'

export default defineNuxtPlugin(() => {
  Amplify.configure(getAmplifyConfig())
  cognitoUserPoolsTokenProvider.setKeyValueStorage(getCookieStorageConfig())

  Hub.listen('auth', async ({ payload }) => {
    switch (payload.event) {
      case 'signedIn':
        await setCurrentUser()
        break
      case 'signedOut':
        currentUser.value = undefined
        break
      case 'signInWithRedirect_failure':
        if (
          payload.data?.error?.message ===
          'PreSignUp+failed+with+error+Account+has+been+merged%2C+please+sign+in+again..+'
        ) {
          // eslint-disable-next-line no-console
          console.info('Logging in again after account merge')
          login()
        }
        break
    }
  })

  const { data: currentUser, refresh: setCurrentUser } = useAsyncData(
    'currentUser',
    () => getCurrentUser(),
    { server: false }
  )

  return {
    provide: {
      auth: {
        currentUser,
        getCurrentUser,
        getToken,
        login,
        logout
      }
    }
  }
})
