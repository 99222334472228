import type { ResourcesConfig } from 'aws-amplify'
import { CookieStorage } from '@aws-amplify/core'

export const getCookieStorageConfig = () =>
  new CookieStorage({
    domain: import.meta.env.VITE_AUTH_COOKIE_DOMAIN,
    secure: !import.meta.env.DEV,
    path: '/',
    expires: 30,
    sameSite: 'lax'
  })

export const getAmplifyConfig = () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`

  return {
    Auth: {
      Cognito: {
        userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
        userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_WEBCLIENT_ID,
        identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
        allowGuestAccess: false,
        signUpVerificationMethod: 'code',
        loginWith: {
          oauth: {
            domain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN,
            scopes: [
              'email',
              'openid',
              'profile',
              'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: [`${baseUrl}/login/auth-callback/`],
            redirectSignOut: [`${baseUrl}/`],
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
          }
        }
      }
    }
  } satisfies ResourcesConfig
}
